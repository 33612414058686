import React from "react";

class SocialNavbar extends React.Component {
  render() {
    return(
      <div className="navbar-social">
        <acronym title="@anuxa.illustration"><a href="https://www.instagram.com/anuxa.illustration/" target="_blank" rel="noopener noreferrer"><i id="instagram-icon" className="fa-brands fa-instagram fa-lg navbar-social-item"></i></a></acronym>
        <acronym title="@anapaulagomesilva"><a href="https://www.behance.net/anapaulagomesilva" target="_blank" rel="noopener noreferrer"><i id="behance-icon" className="fa-brands fa-behance fa-lg navbar-social-item"></i></a></acronym>
        <acronym title="ana-paula-gomes-220527a4"><a href="https://www.linkedin.com/in/ana-paula-gomes-220527a4/" target="_blank" rel="noopener noreferrer"><i id="linkedin-icon" className="fa-brands fa-linkedin-in fa-lg navbar-social-item"></i></a></acronym>
        <acronym title="anapaula.gomesilva@gmail.com"><a href="https://mail.google.com/mail/?view=cm&source=mailto&to=anapaula.gomesilva@gmail.com" target="_blank" rel="noopener noreferrer"><i id="email-icon" className="fa-regular fa-envelope fa-lg navbar-social-item"></i></a></acronym>
      </div>
    );
  }
}

export default SocialNavbar;
