import React from "react";

class IllustrationContent extends React.Component {
  static imageCount = 0;
  
  constructor(props) {
    super(props);
    
    const { images, numberOfColumns, prefix } = this.props;

    this.prefix = prefix;
    this.columns = [];
    for (let i = 0; i < numberOfColumns; i++) {
      this.columns.push([]);
    }

    for (let i = 0; i < images.length; i++) {
      const item = <li key={i}><img className="container-img" src={this.getImageThumbPath(i)} alt="Ilustração" onClick={ () => this.displayImageModal(i) } /></li>;

      const columnIndex = i % this.columns.length;
      this.columns[columnIndex].push(item);
    }
  }

  getImageThumbPath(imageId) {
    const { images } = this.props;
    return `${process.env.PUBLIC_URL}/assets/${this.prefix }/thumbs/${images[imageId]}`;
  }

  displayImageModal(imageId) {
    if (this.columns.length === 1) {
      return;
    }

    const { parent } = this.props;
    parent.setState({ selectedImage: imageId, prefix: this.prefix });
    document.body.style.overflowY = "hidden";
  }

  render() {
    return(
      <div className="illustration-container unselectable">
        { this.columns.map(column => {
          return <div className="illustration-column">
            <ol>{column}</ol>
          </div>
        })}
      </div>
    );
  }
}

export default IllustrationContent;
