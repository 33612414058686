import React from 'react';

import '../index.css';

import Header from './Header';
import Navbar from './Navbar';
import SocialNavbar from './SocialNavbar';
import IllustrationContent from './IllustrationContent';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import ImageModal from './modals/ImageModal';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.imageNames = [
      "(18)anapaula-01_2024-projetopessoal.jpeg",
      "(19)anapaula-01_2024-projetopessoal.jpeg",
      "(20)anapaula-01_2024-projetopessoal.jpeg",
      "01-diadechuva.png",
      "02-castelinho.jpeg",
      "03-agnes.jpeg",
      "04-baleia.jpeg",
      "05-agnes.jpeg",
      "06-batmóvel.jpeg",
      "07-urso.png",
      "08-chapeuzinho.jpeg",
      "09-agnes.jpeg",
      "10-porquinhos.jpeg",
      "11-flautista.jpeg",
      "12-fada.png",
      "13-wandinha.jpeg",
      "14-ajudares.jpeg",
      "15 - vênus.jpeg",
      "16-cogumelos.jpeg",
      "17-ELFA.jpeg"
    ];
    this.bwImageNames = [
      "01-b&w.jpeg",
      "02-b&w.jpeg",
      "03-b&w.jpeg"
    ];
    this.numberOfColumns = window.outerWidth > 991 ? 2 : 1;

    const navItemSelected = Number(localStorage.getItem("nav-item-selected"));
    this.state = {
      navItemSelected: navItemSelected || 0,
      prefix: localStorage.getItem("prefix") || null,
      selectedImage: null,
      lang: localStorage.getItem("lang") ||"pt-br",
      isBW: navItemSelected === 1
    };
  }

  saveCurrentState() {
    localStorage.setItem("nav-item-selected", this.state.navItemSelected);
    localStorage.setItem("prefix", this.state.prefix);
    localStorage.setItem("lang", this.state.lang);
  }

  applyBlackAndWhiteStyle() {
    var allElements = document.body.getElementsByTagName("*");
    for (let element of allElements) {
      element.className += " black-and-white";
    }
  }

  applyNormalStyle() {
    var allElements = document.body.getElementsByTagName("*");
    for (let element of allElements) {
      element.className = element.className.replaceAll(" black-and-white", "");
    }
  }

  componentDidMount() {
    this.state.isBW
      ? this.applyBlackAndWhiteStyle() 
      : this.applyNormalStyle();
  }

  render() {
    return(
      <div id="main">
        { this.state.selectedImage != null && <ImageModal parent={this} imageNames={this.state.prefix === "b_w" ? this.bwImageNames : this.imageNames} prefix={this.state.prefix}/> }
        <Header parent={this} lang={this.state.lang}/>
        <div className="navbar">
          <Navbar parent={this} lang={this.state.lang} />
          <SocialNavbar />
        </div>
        <div id="page-content">
          { this.state.navItemSelected === 0 && <IllustrationContent parent={this} images={this.imageNames} numberOfColumns={this.numberOfColumns} prefix="illustrations"/> }
          { this.state.navItemSelected === 1 && <IllustrationContent parent={this} images={this.bwImageNames} numberOfColumns={this.numberOfColumns} prefix="b_w"/> }
          { this.state.navItemSelected === 2 && <About lang={this.state.lang} /> }
          { this.state.navItemSelected === 3 && <Contact lang={this.state.lang} /> }
          <Footer lang={this.state.lang}/>
        </div>
      </div>
    );
  }
}

export default App;

