import React from "react";

import { translationTable } from "../translationTable";

class About extends React.Component {
  getMeImagePath() {
    return `${process.env.PUBLIC_URL}/assets/me.jpeg`;
  }

  render() {
    const { lang } = this.props;

    return(
      <div className="content-container">
        <img className="me-img" src={this.getMeImagePath()}/>
        <p className="paragraph">{ translationTable[lang]["about-me-1"] }</p>
        <p className="paragraph">{ translationTable[lang]["about-me-2"] }</p>
        <p className="paragraph">{ translationTable[lang]["about-me-3"] }</p>
        <p className="paragraph">{ translationTable[lang]["about-me-4"] }</p>
      </div>
    );
  }
}

export default About;
