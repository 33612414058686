export const translationTable = {
    "pt-br": {
        "country-flag-classnames": ["fi fi-br", "fi fi-pt"],
        "language-selector": "idioma: ",
        "nav-item-1": "ilustração",
        "nav-item-2": "b&w",
        "nav-item-3": "sobre mim",
        "nav-item-4": "contato",
        "about-me-1": "Olá, meu nome é Ana Paula Gomes, mas prefiro ser chamada de Ana. Sou uma ilustradora brasileira que mora em São Paulo. Em 2017, concluí meu bacharelado em Letras pela Universidade de São Paulo. Posteriormente, em 2021, dei início ao bacharelado em Artes Visuais pela Universidade Estadual Paulista.",
        "about-me-2": "Desde sempre fui uma amante de livros e histórias fantásticas, especialmente, contos de fadas e folclore. No entanto, também adoro uma boa história de terror com vampiros e criaturas míticas. Uma das minhas diversões da adolescência era fazer os trabalhos de literatura da escola, pois isso me dava a desculpa de ilustrar as histórias que eu lia.",
        "about-me-3": "Após concluir os anos finais da escola, parei de desenhar e optei por estudar literatura na faculdade. Embora tenha gostado do curso, percebi que não era nessa área que gostaria de atuar profissionalmente. Foi somente em 2021, durante um período desafiador da minha vida, coincidindo com uma quarentena, que redescobri minha paixão pelo desenho.",
        "about-me-4": "Me apaixonei pela carreira de ilustradora desde o início e sigo me apaixonando cada dia mais. Sinto-me privilegiada por poder dedicar-me à criação e recriação de mundos mágicos, sejam eles provenientes da minha própria imaginação ou inspirados em histórias que tive a oportunidade de ler.",
        "contact-1": "Vamos trabalhar juntos!",
        "back-to-top": "voltar ao topo",
        "copyright-1": "Todos os direitos de imagem reservados."
    },
    "en-us": {
        "country-flag-classnames": ["fi fi-us", "fi fi-gb"],
        "language-selector": "language: ",
        "nav-item-1": "illustration",
        "nav-item-2": "b&w",
        "nav-item-3": "about",
        "nav-item-4": "contact",
        "about-me-1": "Hello, my name is Ana Paula Gomes, but I prefer to be called Ana. I'm a Brazilian illustrator living in São Paulo. In 2017, I completed my bachelor's degree in Literature at Universidade de São Paulo. Later, in 2021, I began a bachelor's degree in Visual Arts at the Universidade Estadual Paulista.",
        "about-me-2": "I've always been a lover of books and fantastical stories, especially fairy tales and folklore. However, I also enjoy a good horror story with vampires and mythical creatures. One of my teenage pastimes was working on literature assignments for school, as it gave me an excuse to illustrate the stories I read.",
        "about-me-3": "After finishing high school, I stopped drawing and chose to study literature in college. Although I enjoyed the course, I realized it wasn't the field I wanted to pursue professionally. It was only in 2021, during a challenging period of my life coinciding with a quarantine, that I rediscovered my passion for drawing.",
        "about-me-4": "I fell in love with the career of an illustrator from the beginning and continue to fall more in love with it each day. I feel privileged to dedicate myself to creating and recreating magical worlds, whether they come from my own imagination or are inspired by stories I've had the opportunity to read.",
        "contact-1": "Let's work together!",
        "back-to-top": "back to top",
        "copyright-1": "All image rights reserved."
    }
};