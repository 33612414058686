import React from "react";

import { translationTable } from "../translationTable";

class Navbar extends React.Component {
  onChangedNavItem(itemId) {
    const { parent } = this.props;

    parent.setState({ 
      navItemSelected: itemId, 
      isBW: itemId === 1 ? true: false 
    }, () => {
      parent.saveCurrentState();
      
      itemId === 1
        ? parent.applyBlackAndWhiteStyle()
        : parent.applyNormalStyle();
    });
  }

  render() {
    const { lang, parent } = this.props;

    return(
      <nav className="navbar">
        <h1 id="illustrator-name">Ana Gomes</h1>
        <ul>
        <li className={`navbar-item ${parent.state.navItemSelected === 0 ? "navbar-selected-item" : ""}`} onClick={ () => this.onChangedNavItem(0) }>{ translationTable[lang]["nav-item-1"] }</li>
        <li className={`navbar-item ${parent.state.navItemSelected === 1 ? "navbar-selected-item" : ""}`} onClick={ () => this.onChangedNavItem(1) }>{ translationTable[lang]["nav-item-2"] }</li>
        <li className={`navbar-item ${parent.state.navItemSelected === 2 ? "navbar-selected-item" : ""}`} onClick={ () => this.onChangedNavItem(2) }>{ translationTable[lang]["nav-item-3"] }</li>
        <li className={`navbar-item ${parent.state.navItemSelected === 3 ? "navbar-selected-item" : ""}`} onClick={ () => this.onChangedNavItem(3) }>{ translationTable[lang]["nav-item-4"] }</li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
