import React from "react";

import { translationTable } from "../translationTable";

import "/node_modules/flag-icons/css/flag-icons.min.css";

class Header extends React.Component {
	getMeImagePath() {
    return `${process.env.PUBLIC_URL}/assets/me.jpeg`;
  }

  render() {
		const { parent, lang } = this.props;

		const flags = [];
		for (let classname of translationTable[lang]["country-flag-classnames"]) {
			flags.push(<span className={classname} style={{ margin: "5px", padding: "1px" }}></span>);
		}

    return(
      <header>
				<img className="header-img" src={this.getMeImagePath()} alt="Me"/>
				<div>
					<label>
						{ translationTable[lang]["language-selector"] }
						{ flags }
					</label>
					<select onChange={ e => parent.setState({ lang: e.target.value }, () => parent.saveCurrentState()) } value={lang}>
						<option value="pt-br">Português</option>
						<option value="en-us">English</option>
					</select>
				</div>
				
      </header>
    );
  }
}

export default Header;
