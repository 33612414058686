import React from "react";

import { translationTable } from "../translationTable";

class Footer extends React.Component {
  render() {
    const { lang } = this.props;
    const year = new Date().getFullYear();

    return(
      <footer>
        <hr style={{ width: "75%"}}/>
        <p style={{ opacity: "0.4" }} id="back-to-top-text"><em>{translationTable[lang]["back-to-top"]}</em></p>
        <a href="#"><i className="fa-solid fa-angle-up fa-2xl centered" id="back-to-top-icon"/></a>
        <p className="footer-paragraph centered"><em>{translationTable[lang]["copyright-1"]}</em></p>
        <p className="footer-paragraph centered"><em>© Ana Gomes {year}</em></p>
      </footer>
    );
  }
}

export default Footer;
