import React from "react";

import { translationTable } from "../translationTable";

class Contact extends React.Component {
  getContactImagePath() {
    return `${process.env.PUBLIC_URL}/assets/contact.png`;
  }

  render() {
    const { lang } = this.props;

    return(
      <div style={{ color: "#8e0000" }} className="content-container">
        <p style={{ fontSize: "xx-large", margin: 0 }} className="paragraph centered">{ translationTable[lang]["contact-1"] }</p>
        <p style={{ margin: 0, padding: 0}} className="paragraph centered">anapaula.gomesilva@gmail.com</p>
        <img className="contact-img" src={this.getContactImagePath()}/>
      </div>
    );
  }
}

export default Contact;
