import React from "react";

class ImageModal extends React.Component {
	getImagePath(imageId) {
		const { imageNames, prefix } = this.props;
		console.log(prefix);
		console.log(imageId);
    return `${process.env.PUBLIC_URL}/assets/${prefix}/${imageNames[imageId]}`;
  }

	hideImageModal() {
		const { parent } = this.props;
		parent.setState({ selectedImage: null });
		document.body.style.overflowY = "visible";
	}
    
	goToPreviousImage(e) {
		const { parent } = this.props;

		e.stopPropagation();
		if (parent.state.selectedImage === null) {
			return;
		}

		parent.setState({ selectedImage: --parent.state.selectedImage });
	}
    
	goToNextImage(e) {
		const { parent } = this.props;

		e.stopPropagation();
		if (parent.state.selectedImage === null) {
			return;
		}

		parent.setState({ selectedImage: ++parent.state.selectedImage });
	}
    
	render() {
		const { parent, imageNames } = this.props;

		const isFirstImage = parent.state.selectedImage < 1;
		const isLastImage = parent.state.selectedImage >= imageNames.length - 1;

		return(
			<div className="dimmed-screen unselectable" onClick={ () => this.hideImageModal() }>

				{ !isFirstImage &&
				<div className={"button-box"} onClick={ e => this.goToPreviousImage(e) }>
						<i className="fa-solid fa-angle-left fa-2xl change-img-icon" /> 
				</div>}

				{ isFirstImage &&
				<div className="hidden-button-box">
					<i className="fa-solid fa-angle-left fa-2xl change-img-icon" /> 
				</div>}

				<div style={{ width: "80%", height: "100vh", textAlign: "center" }}>
					<img className="centered-big-img" src={ this.getImagePath(parent.state.selectedImage) }  alt="Ilustração" onClick={ (e) => e.stopPropagation() } />
				</div>

				{ !isLastImage && 
				<div className="button-box" onClick={ e => this.goToNextImage(e) }>
					<i className="fa-solid fa-angle-right fa-2xl change-img-icon" />
				</div> }

				{ isLastImage && 
				<div className="hidden-button-box">
					<i className="fa-solid fa-angle-right fa-2xl change-img-icon" />
				</div> }

			</div>
		);
	}
}

export default ImageModal;
